import './App.css';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import GeographySection from './components/GeographySection';
import WhyUsSection from './components/WhyUsSection';
import ServicesSection from './components/ServicesSection';
import SupportSection from './components/SupportSection';
import ClientsPartnersSection from './components/ClientsPartnersSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
function App() {
  return (
	
    <div className="App">
		<Header />
		<AboutUs />
		<GeographySection />
		<WhyUsSection />
		<ServicesSection />
		<SupportSection />
		<ClientsPartnersSection />
		<ContactSection />
		<Footer />
    </div>
  );
}

export default App;
