import React from 'react';

const SupportSection = () => {
  const circles = [
    '#F58D2A', '#D84028', '#1B476C', '#00739A', '#00AEE0', '#87D4EE'
  ];

  const steps = [
    { number: 1, text: "Первичный сбор информации для оформления заявки" },
    { number: 2, text: "Прогнозирование и планирование доставки" },
    { number: 3, text: "Подготовка необходимых документов" },
    { number: 4, text: "Информационное сопровождение" },
    { number: 5, text: "Успешная доставка" },
  ];

  const cargoSearchData = [
    { груз: "Мебель", объем: "20 м³", вес: "5000 кг", откуда: "Москва", куда: "Санкт-Петербург", дата: "01.07.2023", цена: "50000 ₽" },
    { груз: "Техника", объем: "15 м³", вес: "3000 кг", откуда: "Екатеринбург", куда: "Новосибирск", дата: "05.07.2023", цена: "40000 ₽" },
  ];

  const transportSearchData = [
    { местонахождение: "Москва", типТранспорта: "Фура", доступныйВес: "20000 кг", доступныйОбъем: "80 м³" },
    { местонахождение: "Санкт-Петербург", типТранспорта: "Газель", доступныйВес: "1500 кг", доступныйОбъем: "10 м³" },
  ];

  return (
    <section className="py-12 md:py-16 bg-black text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-8 md:mb-12">Поддержка на всех этапах</h2>
        
        <div className="flex justify-center space-x-4 mb-8 md:mb-12">
          {circles.map((color, index) => (
            <div
              key={index}
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: color }}
            ></div>
          ))}
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6 md:gap-8">
          {steps.map((step, index) => (
            <div key={step.number} className="bg-white rounded-xl shadow-xl p-6 md:p-8 flex flex-col items-center">
              <span
                className="text-6xl md:text-7xl lg:text-8xl font-bold mb-4 md:mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#F58D2A] via-[#D84028] via-[#1B476C] via-[#00739A] via-[#00AEE0] to-[#87D4EE]"
                style={{
                  backgroundPosition: `${index * 25}% 0`,
                  backgroundSize: '500% 100%'
                }}
              >
                {step.number}
              </span>
              <p
                className="text-center text-base md:text-lg font-medium text-transparent bg-clip-text bg-gradient-to-r from-[#F58D2A] via-[#D84028] via-[#1B476C] via-[#00739A] via-[#00AEE0] to-[#87D4EE]"
                style={{
                  backgroundPosition: `${index * 25}% 0`,
                  backgroundSize: '500% 100%'
                }}
              >
                {step.text}
              </p>
            </div>
          ))}
        </div>
      </div>
      
      <div className="container mx-auto px-4 mt-16">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-8">В поисках транспорта</h2>
        <div className="overflow-x-auto mb-12">
          <table className="w-full min-w-max">
            <thead>
              <tr className="text-left border-b border-white">
                <th className="p-2 md:p-4 h-16 text-center">Груз</th>
                <th className="p-2 md:p-4 h-16 text-center">Объем</th>
                <th className="p-2 md:p-4 h-16 text-center">Вес</th>
                <th className="p-2 md:p-4 h-16 text-center">Откуда</th>
                <th className="p-2 md:p-4 h-16 text-center">Куда</th>
                <th className="p-2 md:p-4 h-16 text-center">Дата</th>
                <th className="p-2 md:p-4 h-16 text-center">Цена</th>
              </tr>
            </thead>
            <tbody>
              {cargoSearchData.map((item, index) => (
                <tr key={index} className="border-b border-white/20 hover:bg-gray-900">
                  <td className="p-2 md:p-4 h-16 text-center">{item.груз}</td>
                  <td className="p-2 md:p-4 h-16 text-center">{item.объем}</td>
                  <td className="p-2 md:p-4 h-16 text-center">{item.вес}</td>
                  <td className="p-2 md:p-4 h-16 text-center">{item.откуда}</td>
                  <td className="p-2 md:p-4 h-16 text-center">{item.куда}</td>
                  <td className="p-2 md:p-4 h-16 text-center">{item.дата}</td>
                  <td className="p-2 md:p-4 h-16 text-center">{item.цена}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h2 className="text-2xl md:text-3xl font-bold text-center mb-8">В поисках груза</h2>
        <div className="overflow-x-auto">
          <table className="w-full min-w-max">
            <thead>
              <tr className="text-left border-b border-white">
                <th className="p-2 md:p-4 h-16 text-center">Местонахождение</th>
                <th className="p-2 md:p-4 h-16 text-center">Тип транспорта</th>
                <th className="p-2 md:p-4 h-16 text-center">Доступный вес</th>
                <th className="p-2 md:p-4 h-16 text-center">Доступный объем</th>
              </tr>
            </thead>
            <tbody>
              {transportSearchData.map((item, index) => (
                <tr key={index} className="border-b border-white/20 hover:bg-gray-900">
                  <td className="p-2 md:p-4 h-16 text-center">{item.местонахождение}</td>
                  <td className="p-2 md:p-4 h-16 text-center">{item.типТранспорта}</td>
                  <td className="p-2 md:p-4 h-16 text-center">{item.доступныйВес}</td>
                  <td className="p-2 md:p-4 h-16 text-center">{item.доступныйОбъем}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default SupportSection;
