import React, { useState, useEffect, useRef } from 'react';

const AboutUs = () => {
  const stats = [
    { number: 32, text: 'Работников' },
    { number: 4348, text: 'Клиентов' },
    { number: 1290, text: 'Перевозчиков' },
    { number: 6000, text: 'Рейсов' },
  ];

  const circles = [
    '#F58D2A', '#D84028', '#1B476C', '#00739A', '#00AEE0', '#87D4EE'
  ];

  const [counts, setCounts] = useState(stats.map(() => 0));
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    const duration = 2000;
    const frameDuration = 1000 / 60;
    const totalFrames = Math.round(duration / frameDuration);

    const counters = stats.map((stat, index) => {
      let frame = 0;
      const countTo = stat.number;

      return setInterval(() => {
        frame++;
        const progress = frame / totalFrames;
        const currentCount = Math.round(countTo * progress);

        setCounts(prevCounts => {
          const newCounts = [...prevCounts];
          newCounts[index] = currentCount;
          return newCounts;
        });

        if (frame === totalFrames) {
          clearInterval(counters[index]);
        }
      }, frameDuration);
    });

    return () => counters.forEach(counter => clearInterval(counter));
  }, [isVisible]);

  return (
    <section ref={sectionRef} className="bg-black text-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl md:text-5xl font-bold text-center mb-8">О нас</h2>
        <div className="flex justify-center space-x-4 mb-8">
          {circles.map((color, index) => (
            <div
              key={index}
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: color }}
            ></div>
          ))}
        </div>
        <p className="text-center text-lg md:text-xl max-w-4xl mx-auto mb-16">
          Транспортно-экспедиционная компания "REAL CARGO INDUSTRY"SRL предоставляет широкий спектр услуг по оперативной доставке грузов автомобильным и морским видом транспорта.
        </p>
        <div className="relative">
          <div className="absolute inset-0 flex items-center justify-center">
            <svg className="w-full h-auto" viewBox="0 0 972 136" preserveAspectRatio="xMidYMid meet" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 131.29C22.3506 132.798 71.9181 132.899 99.3835 121.233C133.715 106.649 187.519 92.0662 222.875 95.5863C258.232 99.1063 404.782 103.632 470.884 66.42C536.985 29.2078 697.883 33.2307 726.578 41.7795C755.274 50.3282 895.163 -1.97002 971 4.56726" stroke="url(#paint0_linear_307_6)" strokeOpacity="0.4" strokeWidth="8" strokeDasharray="32 32"/>
              <defs>
                <linearGradient id="paint0_linear_307_6" x1="1" y1="132" x2="971" y2="132" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#F58D2A"/>
                  <stop offset="0.208333" stopColor="#D84028"/>
                  <stop offset="0.401042" stopColor="#1B476C"/>
                  <stop offset="0.609375" stopColor="#00739A"/>
                  <stop offset="0.807292" stopColor="#00AEE0"/>
                  <stop offset="1" stopColor="#87D4EE"/>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-16 py-8 relative z-10">
            {stats.map((stat, index) => (
              <div key={index} className="flex flex-col items-center justify-center">
                <span className="text-3xl md:text-4xl lg:text-5xl font-bold mb-2">
                  {counts[index]}
                  {stat.number >= 1000 ? '+' : ''}
                </span>
                <span className="text-xs md:text-sm lg:text-base text-center">{stat.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
