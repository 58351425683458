import React from 'react';

const WhyUsSection = () => {
  const circles = [
    '#F58D2A', '#D84028', '#1B476C', '#00739A', '#00AEE0', '#87D4EE'
  ];

  const advantages = [
    {
      title: "Надежность",
      description: "Наша компания является надежным партнером для клиентов, предоставляя стабильные и безопасные услуги доставки с акцентом на сохранность груза."
    },
    {
		title: "Гибкость",
		description: '"REAL CARGO INDUSTRY" SRL адаптируется к потребностям клиентов, предоставляя гибкие логистические решения и индивидуальный подход к каждому заказу.'
	  },
	  {
		title: "Технологии",
		description: "Мы внедряем современные технологии, такие как системы отслеживания грузов, для обеспечения прозрачности и контроля в режиме реального времени."
	  },
	  {
		title: "Обслуживания",
		description: "Мы придаем высший приоритет качеству обслуживания, предоставляя клиентам профессиональное и дружелюбное обслуживание с момента заказа до доставки."
	  },
	  {
		title: "Доступность",
		description: 'Связь с "REAL CARGO INDUSTRY" SRL легка и удобна, обеспечивая клиентам доступ к оперативной поддержке и информации о статусе груза в любое время.'
	  },
	  {
		title: "Тарифы",
		description: "Мы предлагаем конкурентоспособные тарифы для наших услуг, обеспечивая клиентам оптимальное соотношение цены и качества."
	  },
  ];

  return (
    <section className="bg-black py-16 text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">Почему мы?</h2>
        
        <div className="flex justify-center space-x-4 mb-8">
          {circles.map((color, index) => (
            <div 
              key={index}
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: color }}
            ></div>
          ))}
        </div>
        
        <p className="text-lg md:text-xl text-center max-w-3xl mx-auto mb-16">
          Наши ключевые преимущества включают оперативность, надежность и гибкость. Мы стремимся к 100% удовлетворенности клиентов, предоставляя индивидуальные решения для каждого заказа.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-12">
          {advantages.map((advantage, index) => (
            <div key={index} className="flex flex-col">
              <div className="flex items-center mb-4">
                <div 
                  className="w-16 h-16 rounded-full flex-shrink-0 mr-4"
                  style={{ backgroundColor: circles[index % circles.length] }}
                ></div>
                <h3 className="text-xl font-semibold">{advantage.title}</h3>
              </div>
              <p className="text-base text-left">{advantage.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUsSection;
