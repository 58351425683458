import React from 'react';

const Footer = () => {
  const navigationLinks = [
    { name: 'О нас', href: '#' },
    { name: 'Почему мы?', href: '#' },
    { name: 'Услуги', href: '#' },
    { name: 'Этапы', href: '#' },
    { name: 'Контакты', href: '#' },
  ];

  const socialLinks = [
    { name: 'Facebook', href: '#' },
    { name: 'WhatsApp', href: '#' },
    { name: 'Viber', href: '#' },
    { name: 'Instagram', href: '#' },
  ];

  return (
    <footer className="bg-black text-white py-8 sm:py-12 lg:py-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 items-start">
          <div className="flex flex-col justify-center space-y-4">
            <h3 className="text-3xl sm:text-4xl font-bold">RCI</h3>
            <p className="text-sm sm:text-base">Кишинёв , ул. Мирчя чел Бэтрын 5/1 Этаж 4</p>
            <p className="text-sm sm:text-base">+373 780 413 01</p>
            <p className="text-sm sm:text-base">realcargoindustry@yandex.ru</p>
          </div>
          
          <div className="flex flex-col justify-center">
            <h4 className="text-lg sm:text-xl font-semibold mb-4">Навигация</h4>
            <ul className="space-y-2">
              {navigationLinks.map((link) => (
                <li key={link.name}>
                  <a href={link.href} className="text-sm sm:text-base hover:text-gray-300 transition-colors duration-200">
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          
          <div className="flex flex-col justify-center">
            <h4 className="text-lg sm:text-xl font-semibold mb-4">Социальные сети</h4>
            <ul className="space-y-2">
              {socialLinks.map((link) => (
                <li key={link.name}>
                  <a href={link.href} className="text-sm sm:text-base hover:text-gray-300 transition-colors duration-200">
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        
        <div className="mt-8 sm:mt-12 flex justify-center space-x-4 sm:space-x-8">
          {[1, 2, 3].map((index) => (
            <img 
              key={index}
              src="/img/logo/RCI.png" 
              alt={`Logo ${index}`} 
              className="h-6 sm:h-8 lg:h-10"
            />
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
