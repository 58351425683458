import React, { useState } from 'react';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    description: ''
  });

  const circles = [
    '#F58D2A', '#D84028', '#1B476C', '#00739A', '#00AEE0', '#87D4EE'
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', formData);
  };

  return (
    <section className="py-16 md:py-24 bg-black text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-8">Контакты</h2>
        
        <div className="flex justify-center space-x-4 mb-12">
          {circles.map((color, index) => (
            <div
              key={index}
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: color }}
            ></div>
          ))}
        </div>
        
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="w-full lg:w-2/3 flex flex-col">
            <div className="mb-4">
              <h3 className="text-xl font-semibold mb-2">Кишинёв , ул. Мирча чел Бэтрын 5/1 Этаж 4</h3>
              <p className="mb-1">+373 780 413 01</p>
              <p>realcargoindustry@yandex.ru</p>
            </div>
            <div className="flex-grow">
              <iframe className='rounded-xl'
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2719.4970880948604!2d28.817899315591936!3d47.03752297915206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c3c2a80b3c5%3A0x3e8b5c0b0b5b5b5b!2sMircea%20cel%20B%C4%83tr%C3%A2n%205%2F1%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2s!4v1623345678901!5m2!1sen!2s"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
          
          <div className="w-full lg:w-1/3 flex flex-col">
            <h3 className="text-xl font-semibold mb-4">Напишите нам</h3>
            <form onSubmit={handleSubmit} className="bg-white p-6 flex-grow flex flex-col rounded-xl">
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Имя"
                className="rounded-md w-full px-3 py-2 text-gray-700 border border-black focus:outline-none focus:border-blue-500 mb-4"
                required
              />
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
                className="rounded-md w-full px-3 py-2 text-gray-700 border border-black focus:outline-none focus:border-blue-500 mb-4"
                required
              />
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Телефон"
                className="rounded-md w-full px-3 py-2 text-gray-700 border border-black focus:outline-none focus:border-blue-500 mb-4"
                required
              />
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Описание услуги"
                className="rounded-md w-full px-3 py-2 text-gray-700 border border-black focus:outline-none focus:border-blue-500 mb-6 flex-grow"
                required
              ></textarea>
              <button
                type="submit"
                className="w-full rounded-md py-2 px-4 bg-gradient-to-r from-[#F58D2A] via-[#D84028] via-[#1B476C] via-[#00739A] via-[#00AEE0] to-[#87D4EE] text-white font-bold hover:opacity-90 transition duration-300"
              >
                Отправить
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
