import React from 'react';

const ClientsPartnersSection = () => {
  const circles = [
    '#F58D2A', '#D84028', '#1B476C', '#00739A', '#00AEE0', '#87D4EE'
  ];

  const logos = [
    '/img/logo/crafti.png',
    '/img/logo/dina.png',
    '/img/logo/gorgan.png',
    '/img/logo/nefis.png',
    '/img/logo/piramida.png',
    '/img/logo/vefasistem.png',
  ];

  const cards = [
    {
      title: "Заказать перевозку",
      description: "Если у Вас есть груз, который необходимо перевезти, Вы на правильном пути! Оставьте заявку на сайте и Мы подберём для вас самые выгодные условия и надёжного перевозчика",
    },
    {
      title: "Стать партнером",
      description: "Если Вы в поисках грузов для перевозки, оставьте заявку с Вашими данными и Мы подберём для Вас выгодное предложение, на лучших условиях и своевременной оплате.",
    },
  ];

  return (
    <section className="bg-black text-white py-12 md:py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-8">Клиенты и Партнеры</h2>
        <div className="flex justify-center space-x-4 mb-8">
          {circles.map((color, index) => (
            <div
              key={index}
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: color }}
            ></div>
          ))}
        </div>
        <p className="text-center text-lg md:text-xl mb-8">
          Мы гордимся тем, что обслуживаем широкий спектр клиентов, включая:
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 mb-12">
          {logos.map((logo, index) => (
            <div key={index} className="flex items-center justify-center">
              <img src={logo} alt={`Client logo ${index + 1}`} className="max-w-full h-auto" />
            </div>
          ))}
        </div>
        <div className="flex flex-col md:flex-row justify-center items-stretch gap-8">
          {cards.map((card, index) => (
            <div key={index} className="bg-white rounded-xl shadow-xl p-6 md:p-8 flex flex-col justify-between w-full md:max-w-md">
              <div className='text-black'>
                <h3 className="text-2xl md:text-3xl font-bold mb-4 text-left">{card.title}</h3>
                <p className="text-base md:text-lg mb-6 text-left">{card.description}</p>
              </div>
              <div className="text-left">
                <button className="px-6 py-2 rounded-md text-white font-semibold bg-gradient-to-r from-[#F58D2A] via-[#D84028] via-[#1B476C] via-[#00739A] via-[#00AEE0] to-[#87D4EE] hover:opacity-90 transition-opacity">
                  Подробнее
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClientsPartnersSection;
