import React from 'react';

const ServicesSection = () => {
  const circles = [
    '#F58D2A', '#D84028', '#1B476C', '#00739A', '#00AEE0', '#87D4EE'
  ];

  const services = [
    {
      title: 'Логистика',
      description: 'Наша команда профессионалов стремится обеспечить бесперебойную и оптимизированную передачу товаров от производителя до потребителя.',
      bgColor: 'bg-[#D14124]',
      bgOpacity: 'bg-opacity-60',
      bgImage: '/img/service_1.jpg',
    },
    {
      title: 'Грузоперевозки',
      description: 'Мы - надежная компания, специализирующаяся на предоставлении качественных услуг по перевозке грузов. Наш опытный и профессиональный персонал готов обеспечить доставку ваших грузов.',
      bgColor: 'bg-[#154468]',
      bgOpacity: 'bg-opacity-60',
      bgImage: '/img/service_2.jpg',
    },
    {
      title: 'Страхование',
      description: 'Страхование – это ключевой элемент финансового планирования, который помогает защитить вас от финансовых потерь в случае неожиданных событий. Мы предлагаем разнообразные виды страхования.',
      bgColor: 'bg-[#F68D2E]',
      bgOpacity: 'bg-opacity-60',
      bgImage: '/img/service_3.jpg',
    },
  ];

  return (
    <section className="py-16 md:py-24 bg-black text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-8">Услуги</h2>
        
        <div className="flex justify-center space-x-4 mb-12">
          {circles.map((color, index) => (
            <div
              key={index}
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: color }}
            ></div>
          ))}
        </div>
        
        <p className="text-center text-lg mb-12 max-w-3xl mx-auto">
          "REAL CARGO INDUSTRY" SRL специализируется на оперативной доставке грузов, обеспечивая клиентов быстрыми и надежными логистическими решениями.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div 
              key={index} 
              className={`relative overflow-hidden rounded-lg shadow-lg flex flex-col`}
            >
              <div 
                className="absolute inset-0 bg-cover bg-center z-0" 
                style={{
                  backgroundImage: `url(${service.bgImage})`,
                  opacity: 0.2
                }}
              ></div>
              <div className={`${service.bgColor} ${service.bgOpacity} absolute inset-0 z-10`}></div>
              <div className="relative z-20 p-6 flex flex-col h-full">
                <h3 className="text-2xl font-semibold mb-4 text-white">{service.title}</h3>
                <p className="text-white text-left">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
