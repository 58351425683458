import React, { useState } from 'react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="relative w-full h-screen bg-cover bg-center flex flex-col items-center justify-center" style={{ backgroundImage: "url('/img/bg.gif')" }}>
      <nav className="absolute top-0 left-0 w-full p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="text-white text-2xl font-bold">RCI</div>

          <div className="hidden md:flex space-x-6">
            {['О нас', 'Почему мы?', 'Услуги', 'Этапы', 'Контакты'].map((item) => (
              <a key={item} href="#" className="text-white hover:text-gray-300 transition-colors duration-300">
                {item}
              </a>
            ))}
          </div>

          <div className="hidden md:flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="size-6 text-white" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
            </svg>
            <a href="#" className="text-white hover:text-gray-300 transition-colors duration-300">Вход</a>
          </div>

          <button onClick={toggleMenu} className="md:hidden text-white focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </nav>

      {isMenuOpen && (
        <div className="absolute top-16 left-0 w-full bg-black bg-opacity-90 md:hidden">
          <div className="container mx-auto">
            <div className="flex flex-col items-center py-4">
              {['О нас', 'Почему мы?', 'Услуги', 'Этапы', 'Контакты'].map((item) => (
                <a key={item} href="#" className="text-white py-2 hover:text-gray-300 transition-colors duration-300">
                  {item}
                </a>
              ))}
              <a href="#" className="text-white py-2 hover:text-gray-300 transition-colors duration-300 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
                </svg>
                Вход
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="text-center text-white">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
          "REAL CARGO INDUSTRY" SRL
        </h1>
        <p className="text-xl md:text-2xl lg:text-3xl mb-8">
          – ваш надежный партнер в сфере оперативной доставки грузов!
        </p>
      </div>

      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        <button className="px-6 py-3 rounded-md text-white font-semibold bg-gradient-to-r from-[#F58D2C] via-[#D84028] via-[#1B476C] via-[#00739A] via-[#00AEE0] to-[#87D4EE]">
          Заказать доставку
        </button>
        <div className="rounded-md p-0.5 bg-gradient-to-r from-[#F58D2C] via-[#D84028] via-[#1B476C] via-[#00739A] via-[#00AEE0] to-[#87D4EE]">
          <button className="px-6 py-3 rounded-md text-white font-semibold bg-black">
            Стать перевозчиком
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
